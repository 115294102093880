
/* ====================
Footer Section
======================*/

.footer{
    padding:100px 0px 20px;
    position: relative;
    background: #000000;

    .footer-widget{
        .widget-title{
            text-transform: capitalize;
            font-size: 18px;
            color:#fff;
            margin-bottom:20px;
            font-weight: 600;
        }

        p{
            color: rgba(255, 255, 255, 0.7);
        }
       
    }
    
    .footer-links{
        li{
            padding: 5px 0px;
            font-size: 15px;
            color:rgba(255, 255, 255, 0.7);
        }
        a{
            color:rgba(255, 255, 255, 0.7);
            position: relative;
            padding-bottom: 5px;
            transition: all .4s ease;

            &:before{
                position: absolute;
                content:"";
                left: 0px;
                bottom: 0px;
                width: 0%;
                height: 2px;
                background: #fff;
                opacity: 0;
                transition: all .4s ease;
            }

            &:hover{
                color: #fff;

                &:before{
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    .footer-socials{
      
        a{
            font-size: 14px;
            color:#fff;
            text-align: center;
            margin-right: 10px;

            &:hover{
               color:var(--theme-secondary-color);
            }
        }
    }
    
}

.footer-mid{
    padding-bottom: 80px;
}


.footer-btm{
    padding-top: 20px;
    border-top: 1px solid rgba(255,255,255,0.04);
}

.footer-logo{
    margin-right: 20px;
}

.footer-btm-links{
    a{
        color: #fff;
        opacity: .8;
        margin-left: 20px;
        border-left: 1px solid rgba(255,255,255,0.08);
        padding-left: 20px;

        &:hover{
            color:var(--theme-secondary-color);
            opacity: 1;
        }
    }
}


.copyright{
    a{
        color: #fff;
        &:hover{
            color:var(--theme-secondary-color);
        }
    }
}

/*----------------------
Fixed top top bottom
--------------------*/
.fixed-btm-top a{
    width:50px;
    height:50px;
    position: fixed;
    right:20px;
    bottom:50px;
    text-align: center;
    padding-top: 10px;
    width: 50px;
    background: #000;
    color: #fff;
    border: 1px solid rgba(255,255,255,.09);
    z-index: 3;
    opacity: 0;
    visibility: hidden;

    &:hover{
        background: #430158;
        color:#fff;
    }
}

.fixed-btm-top.reveal a{
    opacity: 1;
    visibility: visible;
}


.footer-2{
    background: #031F42;

    .fixed-btm-top a{
        background: #031F42;

        &:hover{
            background: #430158;
        }
    }
   
}

.footer-3{
    // background: #031F42;
    background: #120F2D;

    .fixed-btm-top a{
        background: #031F42;

        &:hover{
            background: #430158;
        }
    }
   
}


.footer-4{
background: #120F2D;
    .copyright{
        color: #fff;
        opacity: .8;
    }   
}