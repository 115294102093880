.blog-item{
	transition: all .4s ease;

    .blog-content{
        padding: 25px 30px 30px 30px;
    }
	.post-thumb {
		img{
			width: 100%;
			transition: all .4s ease;
			border-radius: 4px;
		}
	}

    .post-meta{
        margin-bottom: 15px;
        span{
            color: #647589;
            margin-right: 15px;
            text-transform: capitalize;
            i{
                margin-right: 8px;
            }
        }
      
    }

  

    p{
        margin-bottom: 0px;
    }

    .post-title{
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 15px;
    }

    &:hover{
        box-shadow: 0 14px 59px rgba(0, 0, 0, 0.12);
        border-color: transparent;
    }
        
   
}




/*------------------------
 Blog Sidebar
----------------------------*/

.blog-sidebar{
    padding-left: 30px;

    .widget{
        margin-bottom: 50px;
        margin-bottom: 30px;
        border: 1px dashed #eeeeee;
        padding: 30px;
        border-radius: 7px;
    }

    .widget-title{
        margin-bottom: 30px;
    }
}



//  Search form

.widget-search{
    .search-form{
        position: relative;

        .form-control{
            border: 2px solid transparent;

            &:focus{
                border-color:  #430158;
            }
        }

        .search-submit{
            position: absolute;
            right: 0px;
            top: 0px;
            height: 100%;
            background: transparent;
            color: #000;
        }
    }
}

//  Latest posts
.single-latest-post{
    margin-bottom: 30px;

    &:last-child{
        margin-bottom: 0px;
    }
}



.widget-thumb{
    margin-right: 20px;
    float: left;
    img{
        width: 75px;
        height: 75px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
    }
}

.widget-content{
    overflow: hidden;
    h5{
        margin-bottom: 3px;
    }

   span{
       font-size: 14px;
       color: #979797;
       text-transform: capitalize;
        i{
            margin-right: 4px;
        }
   }
}

//  CaTegories

.widget_categories{
 ul {
     li{
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 10px 20px;
         background: #F4F5F8;
         margin-bottom: 10px;
         border-radius: 5px;
         transition: all .4s ease;
         text-transform: capitalize;

         a{
            color: #000;


            &:hover{
                color: #430158;
            }
         }

            &:hover{
                margin-left: 10px;
            }
        }
    }
}


// Tags

.widget_tag_cloud{
    a{
        padding: 3px 20px;
        border: 1px solid #F4F5F8;
        background: transparent;
         display: inline-block;
         margin-bottom: 5px;
         color: #000;
         border-radius: 5px;

         &:hover{
             background: #430158;
             border-color: #430158;
             color: #fff;
         }
    }
}


// :playing
.blog-page-navigation .pagination {
	display: inline-flex;
	margin: 10px 0 0 0;

    li a {
        height: 50px;
        width: 50px;
        margin-right: 10px;
        border: 1px solid #eef0f6;
        border-radius: 0;
        line-height: 49px;
        text-align: center;
        text-decoration: none;
        transition: all 0.4s ease;
        display: block;
        color: var(--theme-text-color);
        font-size: 18px;
        border-radius: 5px;

        &:hover{
            background: #430158;
            color: #fff;
        }

        &.active{
            background: #430158;
            color: #fff;
        }
    }
}




/*---------------------
Single Blog POSt
--------------------------*/ 


.post-single {
    .post-thumb{
        margin-bottom: 30px;
    }
}
.single-post-content{
    .post-title{
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
    }
    .post-meta{
        margin-bottom: 15px;
        span{
            color: #647589;
            margin-right: 15px;
            text-transform: capitalize;
            i{
                margin-right: 8px;
            }
        }
      
    }

    p{
        margin-bottom: 20px;
    }

    
    .inner-title{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    blockquote{
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: #f8f8f8;
        padding: 30px 40px 30px 50px;
        position: relative;
        z-index: 1;
        font-size: 30px;
        font-style: italic;
        color: #000000;
        line-height: 1.4;
        border-left: 5px solid var(--theme-secondary-color);

        &:before {
            content: "\f10d";
            font-family: "Font Awesome 5 Pro";
            font-weight: 600;
            position: absolute;
            right: 30px;
            bottom: 30px;
            line-height: 1;
            font-size: 80px;
            color: #ebebeb;
            z-index: -1;
        }

        cite{
            font-size: 16px;
            display: block;
            margin-top: 20px;
            color: var(--theme-secondary-color);
        }
    }

    img{
        border-radius: 5px;
    }


    ul,ol{
        margin-bottom: 30px;
    }
    ol{
        list-style-type: disc;
        padding-left: 20px;
    }

    ul{
       li{
           position: relative;
            padding-left: 25px;

           &:before {
                content: "\f00c";
                font-family: "Font Awesome 5 Pro";
                font-weight: 600;
                position: absolute;
                z-index: 1;
                left: 0;
                top: 2px;
                font-size: 14px;
            }
       }
    }



}






.blog-footer-meta{
    padding: 30px 0px;
    border-top: 1px solid #eee;
    margin-top: 70px;
}
.article-share{

    h6{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-right: 10px;
    }
    ul {
        li{
            display: inline-block;
            margin: 0px 3px;
            margin-bottom: 5px;
            a{
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 13px;
                color: #0e1133;
                background: #f3f4f8;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;

                &:hover{
                    background: var(--theme-secondary-color);
                    color: #fff;
                }
            }
        }
    }
}


.post-tags{
    a{
        padding: 3px 20px;
        background: #F4F5F8;
         display: inline-block;
         margin-bottom: 5px;
         color: #000;
         border-radius: 5px;

         &:hover{
             background: #430158;
             color: #fff;
         }
    }
}



.post-single-author{
    background: #F4F5F8;
    padding: 40px;
    .author-img{
        float: left;
        margin-right: 40px;
        img{
            width: 120px;
            height: 120px;
            border-radius: 100%;
        }
    }

    .author-info{
        overflow: hidden;
        span{
            display: block;
            margin-bottom: 10px;
            color: var(--theme-secondary-color);
        }
        p{
            margin-bottom: 0px;
        }
    }
}


.common-form{
    .title{
        margin-bottom: 10px;
    }

    p{
        margin-bottom: 30px;
    }
}


.comments{
    .has-children{
        margin-left: 60px;
        display: block;
    }
}
.comment-box{
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    border: 1px dashed #ddd;
    padding: 30px;
    border-radius: 7px;

    .comment-avatar {
        margin-right: 30px;
        img{
            width: 50px;
            height: 50px;
            border-radius: 100%;
        }
    }

    .comment-info{
        h5{
           margin-bottom: 0px;
        }
        span{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            display: inline-block;
        }
        p{
            margin-bottom: 20px;
        }
    }

    .reply-link{
        display: inline-block;
        color: #2b4eff;
        background: rgba(43, 78, 255, 0.1);
        line-height: 22px;
        padding: 3px 8px;
        font-weight: 500;
        font-size: 14px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        i{
            margin-right: 5px;
        }

        &:hover{
            background: var(--theme-secondary-color);
            color: #fff;
        }
    }
}

