
// $primary-font:  'Jost', sans-serif;
$primary-font:  'Gilroy', sans-serif;
// $primary-font:  'Josefin Sans', sans-serif;
// $secondary-font:'Rubik', sans-serif;
$secondary-font: 'Gilroy', sans-serif;
// $secondary-font: 'PT Sans', sans-serif;
$extra-font:'Shadows Into Light', cursive;

$font-color :#5b6674;
$heading-color: #14133b;

$light: #fff;
// $primary-color:#FF5369;
// $primary-color:#f13b3b;
$primary-color:#430158;
// $primary-color:#086AD8;
$secondary-color: #F14D5D;
$green-color: #20ad96;
$yl-color: #ffc221;

$black: #222;
$pure-black: #000;
$border-color:#eee;

$gray : #F4F7FC;
$light-gray: #eee;




